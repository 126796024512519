import { useEffect, useRef } from 'react';

import { experimentInitiated } from 'my-core/gtm-events';
import { Hotjar, mixpanel } from 'my-core/tag-manager';

const STORAGE_KEY = 'wz_experiments';
const ACTIVE_EXPERIMENTS_CONFIG = {
  // mcat_landing_next: [
  //   ['ORIG', 8],
  //   ['NEW', 2]
  // ]
  // mcat_schedule_pricing: [
  //   ['VISIBLE', 1], // Winner https://mixpanel.com/s/4fqmPD
  //   ['HIDDEN', 1],
  // ],
  // app_bar_subscribe_button: [
  //   ['VISIBLE', 1],
  //   ['HIDDEN', 1],
  // ],
  // homepage_hero_height: [
  //   ['ORIGINAL', 1],
  //   ['ADJUSTED', 1], // Indecisive, moving forward with Adjusted height for now to better assess impact of below-hero section experiments
  // ],
  homepage_atf_exp1: [
    // Homepage Above the Fold experiment
    ['ORIGINAL', 1],
    ['SCHOOLS', 1],
  ],
  signup_hide_email_form: [
    ['ORIGINAL', 1],
    ['HIDE_EMAIL_FORM', 1],
  ],
};
let cachedExperimentsState = null;
let localStorageSupported = true;
function getExperimentsState() {
  if (cachedExperimentsState !== null) return cachedExperimentsState;
  try {
    const storedVal = window.localStorage.getItem(STORAGE_KEY) || '{}';
    try {
      cachedExperimentsState = JSON.parse(storedVal);
    } catch (ignoreErr) {
      cachedExperimentsState = {};
      updateExperimentsState();
    }
  } catch (ignoreErr) {
    localStorageSupported = false;
    cachedExperimentsState = {};
  }
  let deletedExps = false;
  Object.keys(cachedExperimentsState).forEach(k => {
    if (!ACTIVE_EXPERIMENTS_CONFIG[k]) {
      delete cachedExperimentsState[k];
      deletedExps = true;
    }
  });
  if (deletedExps) updateExperimentsState();
  return cachedExperimentsState;
}

function updateExperimentsState(updates) {
  let config = getExperimentsState();
  Object.assign(config, updates);
  if (localStorageSupported) {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
  }
}

function getRandomVariant(variantsAndWeights) {
  const bucket = variantsAndWeights.flatMap(([variant, weight]) => Array(weight).fill(variant));
  const index = Math.floor(Math.random() * bucket.length);
  return bucket[index];
}

export default function useExperiment(experimentName, { disabled } = {}) {
  const variantsAndWeights = ACTIVE_EXPERIMENTS_CONFIG[experimentName];
  if (!variantsAndWeights) throw Error("useExperiment has invalid 'experimentName' variable");
  // warning(experimentName, 'useExperiment missing experimentName variable');
  const variantData = useRef();
  if (!disabled && !variantData.current) {
    const expsState = getExperimentsState();
    if (expsState[experimentName] != null) {
      variantData.current = { variant: expsState[experimentName] };
    } else {
      const variant = getRandomVariant(variantsAndWeights);
      updateExperimentsState({ [experimentName]: variant });
      variantData.current = { variant, initialLoad: true };
    }
  }

  useEffect(() => {
    if (disabled) return;
    mixpanel.register({ active_experiments: getExperimentsState() });
    if (variantData.current.initialLoad) {
      experimentInitiated(experimentName, variantData.current.variant);
      Hotjar('event', `experiment_${experimentName}_${variantData.current.variant.toLowerCase()}`);
    }
  }, [experimentName, disabled]);

  return disabled ? undefined : variantData.current.variant;
}
