import { useCallback, useEffect, useRef, useState } from 'react';

import { homepageViewed, landingPageCTAClicked } from 'my-core/gtm-events';
import {
  useCurrentUser,
  useDialogStateManager,
  useExperiment,
  useScrollTo,
  useSearchParams,
  useTitle,
} from 'my-core/hooks';
import { getLandingIntroVideo } from 'my-core/landing-data';

import Footer from 'my-components/Footer';
import { StudentTestimonials, TestimonialsCarousel } from 'my-components/LandingPage';
import LandingPageGetBetterGrades from 'my-components/LandingPageGetBetterGrades';
import LandingPageGetStarted from 'my-components/LandingPageGetStarted';
import LandingPageMCAT from 'my-components/LandingPageMCAT';
import LandingPageSchools from 'my-components/LandingPageSchools';
import LandingPageValueProposition from 'my-components/LandingPageValueProposition';
import ModalVideo from 'my-components/ModalVideo';
import ResetPasswordForm from 'my-components/ResetPasswordForm';

import HomePageBlurbs from './HomePageBlurbs';
import HomePageHero from './HomePageHero';
import HomePageInstructors from './HomePageInstructors';
import HomePageMCATBand from './HomePageMCATBand';
import HomePageSchoolsSection from './HomePageSchoolsSection';
import SubjectBar from './SubjectBar';

import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HomePage() {
  const [searchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const isCanadian = currentUser.country_code === 'CA';

  useTitle('Wizeprep | Course Specific Help');
  useEffect(() => {
    homepageViewed();
  }, []);

  const [resetPasswordToken, setResetPasswordToken] = useState(null);
  useEffect(() => {
    if (searchParams.reset_password_token) {
      setResetPasswordToken(searchParams.reset_password_token);
    }
  }, [searchParams.reset_password_token]);

  const closePasswordForm = useCallback(() => {
    setResetPasswordToken(null);
  }, []);

  const [playerOpen, playerOpened, playerActions] = useDialogStateManager();

  const scrollTo = useScrollTo();
  const valuePropsRef = useRef();

  const atfExperimentVariant = useExperiment('homepage_atf_exp1', { disabled: searchParams.hp_exp });
  const atfExperimentActive = searchParams.hp_exp ? searchParams.hp_exp === 'true' : atfExperimentVariant === 'SCHOOLS';

  return (
    <>
      <HomePageHero onPlayVideo={playerActions.open} />
      {atfExperimentActive ?
        <HomePageSchoolsSection />
      : <LandingPageGetBetterGrades compressed />}
      <Box backgroundColor="background.paper">
        <SubjectBar />
      </Box>
      <HomePageMCATBand />
      <Box backgroundColor="background.paper">
        <HomePageBlurbs />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: { xs: 5, sm: 9 },
          }}
        >
          <MuiLink
            component="button"
            onClick={() => {
              scrollTo(valuePropsRef.current, { extraOffset: 0 });
              landingPageCTAClicked('homepage_blurbs', 'learn_more');
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            underline="hover"
          >
            <Typography color="primary" variant="button">
              Learn How
            </Typography>
            <Typography color="primary" variant="button">
              <FontAwesomeIcon icon={faChevronDown} />
            </Typography>
          </MuiLink>
        </Box>
      </Box>
      <LandingPageValueProposition ref={valuePropsRef} />
      <Box backgroundColor="background.paper">
        <HomePageInstructors />
      </Box>
      <LandingPageSchools title="Find Your School" />
      <LandingPageMCAT />
      <Box backgroundColor="background.paper">
        <StudentTestimonials />
      </Box>
      <TestimonialsCarousel />
      <LandingPageGetStarted color="primary" />
      <Footer />

      <ResetPasswordForm onClose={closePasswordForm} token={resetPasswordToken} />
      {playerOpened && (
        <ModalVideo onClose={playerActions.close} open={!!playerOpen} PlayerProps={getLandingIntroVideo(isCanadian)} />
      )}
    </>
  );
}
